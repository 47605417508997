<template>
  <b-card no-body>
    <b-card-header>
      <div>
        <b-card-title class="mb-1">
          Statistics Order
        </b-card-title>
        <b-card-sub-title>Commercial networks and enterprises</b-card-sub-title>
      </div>
    </b-card-header>

    <!-- chart -->
    <b-card-body>
      <b-row>
        <b-col>
          <date-range-picker
            ref="picker"
            v-model="dateRange"
          >
            <template
              v-slot:input="picker"
              style="min-width: 350px;"
            >
              {{ picker.startDate | formatBirthDate }} - {{ picker.endDate | formatBirthDate }}
            </template>
          </date-range-picker>
        </b-col>
      </b-row>
      <chartjs-component-line-chart
        v-if="loaded == true"
        :key="key"
        :data="chartData"
        :options="options"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardSubTitle, BCardTitle, BRow, BCol,
} from 'bootstrap-vue'

import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

import ChartjsComponentLineChart from '@/components/Dashboard/ChartjsComponentLineChart.vue'
import chartjsData from '@/components/Dashboard/chartjsData'

import moment from 'moment'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BCardSubTitle,
    BCardTitle,
    ChartjsComponentLineChart,
    DateRangePicker,
  },
  data() {
    /* eslint-disable camelcase */
    const startDate = moment().startOf('month').format('YYYY-MM-DD')
    const endDate = moment().format('YYYY-MM-DD')

    return {
      loaded: false,
      dateRange: { startDate, endDate },
      key: 1,
      chartjsData,
      chartData: null,
      options: {
        legend: {
          display: true,
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
            },
          }],
        },
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          callbacks: {
            label(tooltipItem) {
              return tooltipItem.yLabel
            },
          },
        },
      },
    }
  },
  watch: {
    dateRange(value) {
      this.chartData = null
      this.key += 1
      this.dateRange.startDate = moment(value.startDate).format('YYYY-MM-DD')
      this.dateRange.endDate = moment(value.endDate).format('YYYY-MM-DD')
      this.getData()
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.loaded = false
      this.$http.get('/admin/statistic-chart', {
        params: {
          start_date: this.dateRange.startDate,
          end_date: this.dateRange.endDate,
        },
      })
        .then(response => {
          const item = response.data.data
          this.addChart(item)
          this.loaded = true
        })
    },
    addChart(item) {
      const datasetChart = []
      const labelsChart = []
      const labelChart = []
      // eslint-disable-next-line array-callback-return
      item.map(element => {
        labelsChart.push(element.date)
        // eslint-disable-next-line array-callback-return
        element.data.map(items => {
          datasetChart.push({
            x: items.label,
            y: items.count,
          })
          if (!labelChart.includes(items.label)) {
            labelChart.push(items.label)
          }
        })
      })

      this.chartData = {
        labels: labelsChart,
        datasets: [
          {
            label: labelChart,
            data: datasetChart,
            backgroundColor: chartjsData.chartColor.primaryColorShade,
            borderColor: chartjsData.chartColor.primaryColorShade,
            fill: false,
            tension: 0.1,
          },
        ],
      }
    },
  },
}
</script>
