<template>
  <div>
    <div v-if="checkPermission('dashboard statistic')">
      <!-- User & Jasa -->
      <b-row>
        <CardStatisticUser :data="data" />
        <CardStatisticLowongan :data="data" />
      </b-row>
      <!-- Talenta & Lowongan -->
      <b-row>
        <CardStatisticJasa :data="data" />
        <CardStatisticTalenta :data="data" />
      </b-row>
      <!-- Order -->
      <CardStatisticOrder :data="data" />
      <ChartOrder />
    </div>
    <div v-else>
      <b-card title="Welcome 🚀">
        <b-card-text>Welcome to Dashboard.</b-card-text>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BCard, BCardText, BRow,
} from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'

import CardStatisticUser from '@/components/Dashboard/CardStatisticUser.vue'
import CardStatisticJasa from '@/components/Dashboard/CardStatisticJasa.vue'
import CardStatisticTalenta from '@/components/Dashboard/CardStatisticTalenta.vue'
import CardStatisticLowongan from '@/components/Dashboard/CardStatisticLowongan.vue'
import CardStatisticOrder from '@/components/Dashboard/CardStatisticOrder.vue'
import ChartOrder from '@/components/Dashboard/ChartOrder.vue'

export default {
  title() {
    return 'Dashboard'
  },
  components: {
    BRow,
    BCard,
    BCardText,
    CardStatisticUser,
    CardStatisticJasa,
    CardStatisticTalenta,
    CardStatisticLowongan,
    CardStatisticOrder,
    ChartOrder,
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  data() {
    return {
      data: Object,
    }
  },
  mounted() {
    if (checkPermission('dashboard statistic')) {
      this.getSummary()
    }
    // this.reloadUserData()
  },
  methods: {
    getSummary() {
      this.$http.get('/admin/statistic')
        .then(response => {
          this.data = response.data.data
        })
    },
    reloadUserData() {
      try {
        this.$http.get('/me')
          .then(response => {
            if (response.data.data.permissions.length > 0 || response.data.data.roles.length > 0) {
              const userData = JSON.stringify(response.data.data)
              localStorage.setItem('userData', userData)
            }
          })
      // eslint-disable-next-line no-empty
      } catch (error) {}
    },
  },
}
</script>
