<template>
  <b-row>
    <b-col
      lg="12"
      sm="12"
    >
      <b-card
        no-body
        class="card-statistics"
      >
        <b-card-header>
          <b-card-title>Withdraw &amp; Topup</b-card-title>
        </b-card-header>
        <b-card-body
          class="statistics-body"
          style="padding-top: 0 !important"
        >
          <b-row>
            <!-- Withdraw Pay Success -->
            <b-col class="mb-2 mb-md-2 mb-lg-2">
              <b-media no-body>
                <b-media-aside class="mr-2">
                  <b-avatar
                    size="48"
                    variant="light-success"
                  >
                    <feather-icon
                      size="24"
                      icon="BoxIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ data.withdraw_pay_success }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    Withdraw Pital Pay Berhasil
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
            <!-- Withdraw Pay Pending -->
            <b-col class="mb-2 mb-md-2 mb-lg-2">
              <b-media no-body>
                <b-media-aside class="mr-2">
                  <b-avatar
                    size="48"
                    variant="light-secondary"
                  >
                    <feather-icon
                      size="24"
                      icon="BoxIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ data.withdraw_pay_pending }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    Withdraw Pital Pay Pending
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
            <!-- Topup Pay Success -->
            <b-col class="mb-2 mb-md-2 mb-lg-2">
              <b-media no-body>
                <b-media-aside class="mr-2">
                  <b-avatar
                    size="48"
                    variant="light-success"
                  >
                    <feather-icon
                      size="24"
                      icon="BoxIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ data.topup_pay_success }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    Topup Pital Pay Sukses
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
            <!-- Topup Pay Pending -->
            <b-col class="mb-2 mb-md-2 mb-lg-2">
              <b-media no-body>
                <b-media-aside class="mr-2">
                  <b-avatar
                    size="48"
                    variant="light-warning"
                  >
                    <feather-icon
                      size="24"
                      icon="BoxIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ data.topup_pay_pending }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    Topup Pital Pay Pending
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
            <!-- Topup Poin Success -->
            <b-col class="mb-2 mb-md-2 mb-lg-2">
              <b-media no-body>
                <b-media-aside class="mr-2">
                  <b-avatar
                    size="48"
                    variant="light-success"
                  >
                    <feather-icon
                      size="24"
                      icon="BoxIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ data.topup_poin_success }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    Topup Pital Poin Sukses
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
            <!-- Topup Poin Pending -->
            <b-col class="mb-2 mb-md-2 mb-lg-2">
              <b-media no-body>
                <b-media-aside class="mr-2">
                  <b-avatar
                    size="48"
                    variant="light-secondary"
                  >
                    <feather-icon
                      size="24"
                      icon="BoxIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ data.topup_poin_pending }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    Topup Pital Poin Pending
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
            <!-- Withdraw Hasil Usaha Success -->
            <b-col class="mb-2 mb-md-2 mb-lg-2">
              <b-media no-body>
                <b-media-aside class="mr-2">
                  <b-avatar
                    size="48"
                    variant="light-success"
                  >
                    <feather-icon
                      size="24"
                      icon="BoxIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ data.withdraw_hasil_usaha_success }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    Penarikan Hasil Usaha Sukses
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
            <!-- Withdraw Hasil Usaha Pending -->
            <b-col class="mb-2 mb-md-2 mb-lg-2">
              <b-media no-body>
                <b-media-aside class="mr-2">
                  <b-avatar
                    size="48"
                    variant="light-secondary"
                  >
                    <feather-icon
                      size="24"
                      icon="BoxIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ data.withdraw_hasil_usaha_pending }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    Penarikan Hasil Usaha Pending
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BCardText, BRow, BCol, BCardHeader, BCardTitle, BCardBody, BMedia, BMediaAside, BAvatar, BMediaBody,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
  },
  props: {
    /* eslint-disable vue/require-default-prop */
    data: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: '',
    },
  },
}
</script>
