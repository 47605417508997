<template>
  <b-col
    lg="12"
    sm="12"
  >
    <b-card
      no-body
      class="card-statistics"
    >
      <b-card-header>
        <b-card-title>User</b-card-title>
      </b-card-header>
      <b-card-body
        class="statistics-body"
        style="padding-top: 0 !important"
      >
        <b-row>
          <!-- Users -->
          <b-col class="mb-2 mb-md-0">
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar
                  size="48"
                  variant="light-primary"
                >
                  <feather-icon
                    size="24"
                    icon="UserIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ data.users }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  User
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <!-- Active Users -->
          <b-col class="mb-2 mb-md-0">
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar
                  size="48"
                  variant="light-success"
                >
                  <feather-icon
                    size="24"
                    icon="UserIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ data.active_users }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  User Aktif
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <!-- Inactive Users -->
          <b-col class="mb-2 mb-md-0">
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar
                  size="48"
                  variant="light-danger"
                >
                  <feather-icon
                    size="24"
                    icon="UserIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ data.inactive_users }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  User Tidak Aktif
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </b-col>
</template>

<script>
import {
  BCard, BCardText, BRow, BCol, BCardHeader, BCardTitle, BCardBody, BMedia, BMediaAside, BAvatar, BMediaBody,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
  },
  props: {
    /* eslint-disable vue/require-default-prop */
    data: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: '',
    },
  },
}
</script>
